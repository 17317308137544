<template>
  <v-row>
    <formBlock
      v-for="(block, index) in blocks"
      :eagle-form-block="index"
      :block="block"
      :key="index"
      :layerKey="layerKey"
      :blockIndex="index"
      :formKey="formKey"
    ></formBlock>
  </v-row>
</template>

<script>
export default {
  props: {
    formKey: String,
    layerKey: {
      type: String,
      default: null,
    },
    layerBlocks: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    blocks: [],
  }),
  methods: {
    initBlocks() {
      let blocks = this.blockConfig.filter(block => {
        if(typeof block.create != 'function') return true
        return block.create(this.formInfo, this.tokenStore) === true
      })

      if(window.eagleLodash.isEqual(blocks, this.blocks)) return
      this.blocks = blocks
    },
  },
  computed: {
    blockConfig() {
      if(Array.isArray(this.layerBlocks)) return this.layerBlocks
      if(!this.formConfig) return []
      return this.formConfig.blocks
    },
    tokenStore() {
      return this.$store.getters[`token/tokenStore`]
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
  },
  watch: {
    blockConfig: {
      immediate: true,
      deep: true,
      handler() {
        this.initBlocks()
      },
    },
    formData: {
      deep: true,
      async handler() {
        this.initBlocks()
        await this.$nextTick()
        this.formActions.setupPageTitle()
      },
    },
  },
  components: {
    formBlock: () => import('@/components/form/formBlock.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
